<template>
  <div>
    <list-visit-additional-add-new
      ref="listVisitAdditionalSidebar"
    />
    <b-col
      v-if="$can('create', 'additionalVisit')"
      cols="2"
      class="mb-1"
    >
      <b-button
        variant="primary"
        @click="showListVisitAdditionalSidebar"
      >
        <span class="text-nowrap">Добавить</span>
      </b-button>
    </b-col>
    <b-col class="d-flex mb-1">
      <b-button v-b-modal.sections-modal>
        <span class="text-nowrap">Смотреть все секции</span>
      </b-button>
    </b-col>
    <b-col class="d-flex mb-1">
      <b-form-group
        label="Верхняя граница даты"
        label-size="sm"
        label-for="date"
      >
        <b-input-group>
          <b-form-datepicker
            id="date"
            v-model="date"
            locale="ru"
            @input="fetchData"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col class="col-12 mb-1 text-center">
      <div
        :style="{color: isDark ? '#ffffff' : '#6e6b7b'}"
        class="text-nowrap"
        style="font-size: 15px;font-weight: 500"
      >{{ (new Date(date + 'T00:00:00')).toLocaleDateString("ru-RU", {
        month: "long",
      }) }} {{ (new Date(date + 'T00:00:00')).toLocaleDateString("ru-RU", {
        year: "numeric",
      }) }}</div>
    </b-col>
    <b-col
      v-for="(visit, key, index) in items"
      :key="index"
      cols="12"
      class="mb-1"
    >
      <additional-visit-table
        :group-name="key"
        :visit="visit"
        :date="date"
      />
    </b-col>
    <b-modal
      id="sections-modal"
      size="lg"
    >
      <list-section />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCol,
  BFormGroup,
  BFormInput, BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BPagination, BRow,
  BTable, BIconEmojiSmileFill,
  BIconEmojiFrownFill,
  BIconEmojiNeutralFill,
  BIconArrowLeft, BIconArrowRight,
  BModal,
} from "bootstrap-vue"
import ListVisitAdditionalAddNew from "@/views/visit/ListVisitAdditionalAddNew"
import moment from 'moment'
import AdditionalVisitTable from '@/views/visit/AdditionalVisitTable'
import ListSection from "../sections/ListSection.vue"

export default {
  name: 'ListVisitAdditional',
  components: {
    BModal,
    BIconArrowLeft,
    BIconArrowRight,
    AdditionalVisitTable,
    ListVisitAdditionalAddNew,
    ListSection,
    BButton,
    BLink,
    BFormDatepicker,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BPagination,
    BRow,
    BCol,
    BIconEmojiSmileFill,
    BIconEmojiFrownFill,
    BIconEmojiNeutralFill,
  },
  props: {
    kind: {
      type: String,
      required: false,
      default: null,
    }
  },
  data() {
    const d = moment()
    d.locale('ru')
    d.set('date', 1)

    return {
      groupByItems: [],
      createUser: '',
      user: '',
      date: (new Date()).toISOString().split('T')[0],
      status: true,
      userId: 1,
      attendanceId: 1,
      add_days: [
        {
          name: "gsdjg';;'",
          dates: [
            true, false
          ]
        },
        {
          name: "342g';;'",
          dates: [
            true, false
          ]
        },
      ],
      users: [
        {
          id: 1,
          name: 'lorem ipsum',
          attendances: [],
        },
      ],
    }
  },
  computed: {
    isDark() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    },
    items() {
      return this.$store.state.info.visitAdditionalList
    },
  },
  created() {
    this.fetchData(1)
    // this.fetchAllData()
  },
  methods: {
    async fetchAllData() {
      await this.$store.dispatch('info/getChildrenGroups')
      await this.fetchData(1)
    },
    fetchData(page) {
      const { kind } = this.$props
      // const params = {
      //   limit: this.limit,
      //   offset: page === 1 ? 0 : (page * this.limit) - this.limit,
      // }
      // if (this.filter !== '') {
      //   params.search = this.filter
      // }
      const params = {
        date: this.date || undefined,
        kind,
      }
      this.$store.dispatch('info/getVisitAdditional', params)
    },
    showListVisitAdditionalSidebar() {
      if (!this.$refs.listVisitAdditionalSidebar.isAddListVisitAdditionalSidebarActive) {
        this.$refs.listVisitAdditionalSidebar.isAddListVisitAdditionalSidebarActive = true
      }
    },
  }

}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/components/table.scss';
.modal-content {
  // width: max-content !important;
  // min-width: 75% !important;
}
</style>

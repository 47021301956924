<template>
  <div>
    <b-sidebar
      id="add-new-list-visit-additional-sidebar"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      v-model="isAddListVisitAdditionalSidebarActive"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Добавить</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="submitChild"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="{ errors }"
              name="Дата"
              rules="required"
            >
              <b-form-group label="Дата" label-for="datepicker-placeholder">
                <b-form-datepicker
                  v-model="visit_date"
                  id="datepicker-placeholder"
                  placeholder="Выберите дату"
                  rules="required"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <b-button variant="primary" @click="showTable = true"
              >Выбрать детей</b-button
            >
            <b-form-textarea
              class="mt-1 mb-1 bg-transparent"
              disabled
              v-model="selectedChildrenNames"
            >
            </b-form-textarea>
            <validation-provider
              #default="{ errors }"
              name="Имя сотрудника"
              rules="required"
            >
              <b-form-group label="Имя сотрудника" label-for="full-name_worker">
                <b-form-select
                  id="full-name_worker"
                  autofocus
                  v-model="worker"
                  trim
                  placeholder="Имя сотрудника"
                  :state="errors.length > 0 ? false : null"
                >
                  -
                  <b-form-select-option
                    :value="workers.id"
                    :key="index"
                    v-for="(workers, index) in workerOptions"
                  >
                    {{ workers.full_name }}
                  </b-form-select-option>
                </b-form-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Статус посещения"
            >
              <b-form-group label="Статус посещения" label-for="visitStatus">
                <b-form-select
                  :options="visitStatus"
                  v-model="visit_status"
                  id="visitStatus"
                  placeholder="Статус посещения"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Секция"
            >
              <b-form-group label="Секция" label-for="sections">
                <b-form-select
                  v-model="section_id"
                  id="section"
                  placeholder="Секция"
                >
                  <b-form-select-option
                    :value="section.id"
                    :key="index"
                    v-for="(section, index) in sectionOptions"
                  >
                    {{ section.name }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Причина отсутствия"
              label-for="reason"
              v-if="visit_status === 'b'"
            >
              <b-form-input
                id="reason"
                v-model="reason"
                placeholder="Причина отсутствия"
              />
            </b-form-group>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Сохранить
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="isAddListVisitAdditionalSidebarActive = false"
              >
                Отмена
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-modal title="Выберите детей" size="lg" v-model="showTable">
      <b-row>
        <b-col cols="12">
          <children-table
            :only-available-children="true"
            @new-page="selectChildren"
            @items-selected="childSelected"
          ></children-table>
        </b-col>
        <!--        <b-col cols="12">-->
        <!--          <b-form-textarea v-model="selectedChildrenNames">-->

        <!--          </b-form-textarea>-->
        <!--        </b-col>-->
      </b-row>
      <template #modal-footer>
        <b-button @click="selectChildrenModal" variant="success">OK</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BCol,
  BRow,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode";
import ChildrenTable from "@/views/components/ChildrenTable";
import {emojiStatusForAdditional} from "../../libs/emoji-status";

function initialState() {
  return {
    childrenToSelect: [],
    showTable: false,
    section_id: null,
    reason: "",
    isAddListVisitAdditionalSidebarActive: false,
    childOptions: [],
    workerOptions: [],
    sectionOptions: [],
    selectedChildren: [],
    selectedChildrenNames: "",
    visit_date: "",
    child: "",
    worker: "",
    visit_status: "",
    required,
    alphaNum,
    visitStatus: [
      // {
      //   value: "Ж",
      //   text: `${emojiStatusForAdditional("Ж")} Отсутствует ребенок по желанию родителей`,
      // },
      {
        value: "О",
        text: `${emojiStatusForAdditional("О")} Отсутствует без предупеждения родителей`,
      },
      { value: "Был", text: `${emojiStatusForAdditional("Был")} Был в саду` },
    ],
  };
}
export default {
  components: {
    BCol,
    BRow,
    BFormTextarea,
    ChildrenTable,
    BCardCode,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    flatPickr,
    BFormSelectOption,
    vSelect,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return initialState();
  },

  created() {
    // this.$store.dispatch('info/getChildren')
    //     .then(() => {
    //       this.childOptions = this.children
    //     })
    this.$store.dispatch("info/getWorkers").then(() => {
      this.workerOptions = this.workers;
    });
    this.$store.dispatch("info/getSections").then(() => {
      this.sectionOptions = this.sections;
    });
  },
  computed: {
    children() {
      return this.$store.state.info.childrenList;
    },
    workers() {
      return this.$store.state.info.workersList;
    },
    sections() {
      return this.$store.state.info.sections;
    },
  },
  methods: {
    childSelected(items) {
      this.childrenToSelect = items;
    },
    selectChildrenModal() {
      this.selectChildren();
      this.showTable = false;
    },
    selectChildren() {
      this.childrenToSelect.forEach((c) => {
        if (!this.selectedChildren.find((child) => child.id === c.id)) {
          this.selectedChildren.push(c);
        }
      });
      this.selectedChildrenNames = this.selectedChildren
        .map((i) => i.full_name)
        .join(", ");
    },
    submitChild() {
      this.$store
        .dispatch("info/listVisitAdditionalScheduleCreate", {
          children: this.selectedChildren.map((c) => c.id),
          worker: this.worker,
          visit_date: this.visit_date,
          visit_status: this.visit_status,
          section_id: this.section_id,
          // visit_status: this.visit_status === 'a' ? 'Был' : this.reason,
        })
        .then(() => {
          this.$store.dispatch("info/getVisitAdditional");
          this.isAddListVisitAdditionalSidebarActive = false;
          this.$toast.success("Визит сохранён", { timeout: 2000 });
          this.$store.dispatch("info/getWorkers").then(() => {
            this.workerOptions = this.workers;
          });
          this.$store.dispatch("info/getSections").then(() => {
            this.sectionOptions = this.sections;
          });
          Object.assign(this.$data, initialState());
        })
        .catch((err) => {
          this.$toast.error("Не удалось сохранить визит", { timeout: 2000 });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-list-visit-additional-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

<!-- eslint-disable no-underscore-dangle -->
<template>
  <div>
    <b-button
      class="mb-1"
      block
      variant="info"
      @click="visible = !visible"
    >{{ groupName }}</b-button>
    <div
      v-if="visible"
      class="list-view-row  d-flex w-100 justify-content-between border-top"
    >
      <div class="list-visit-day border-left">
        №
      </div>
      <div class="list-visit-child-name border-right border-left ">
        <div class="text-center">
          ФИО Ребенка
        </div>
      </div>
      <div
        v-for="(n, index) in Array(no_of_days)"
        :key="n + 1"
        :class="{
          'bg-warning': isWeekend(n + 1),
          'list-visit-day': true,
          'head': true,
          'border-right': true

        }"
      >
        <div>{{ index + 1 }}</div>
      </div>
      <div
        :class="{
          'list-visit-day': true,
          'head': true,
          'border-right': true
        }"
      >
        <div>Кол-во посещений</div>
      </div>
    </div>
    <div
      v-for="(child, index) in visit"
      v-if="visible"
      :key="index"
      :class="{
        'list-view-row': true,
        'd-flex': true,
        'w-100': true,
        'justify-content-between': true,
        'border-top': true,
        'border-bottom': (visit.length - 1) === index
      }"
    >
      <div class="list-visit-day border-left">
        {{ index+1 }}
      </div>
      <div class="list-visit-child-name border-right border-left">
        <div>{{ child.name }}</div>
      </div>
      <div
        v-for="(n, idx) in Array(no_of_days)"
        :key="n + 1"
        :class="{'list-visit-day': true, 'value': true, 'border-right': true, 'bg-warning': isWeekend(idx + 1)}"
      >
        <div>
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              {{ checkVisit(idx + 1, child) }} <span class="sr-only">status</span>
            </template>
            <b-dropdown-item
              v-for="(status) in visitStatus"
              :key="status.value"
              @click="handleEditVisit({section_id: child.section_id, id: getVisitId(idx + 1, child), children: [child.id], status: status.value, day: idx + 1})"
            >{{ status.text }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div
        :class="{'list-visit-day': true, 'value': true, 'border-right': true}"
      >
        <div>{{ sumVisits(groupName, child) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
 BButton, BIconEmojiSmileFill, BIconEmojiFrownFill, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import moment from 'moment'
import { emojiStatusForAdditional } from '../../libs/emoji-status'

const monthDays = year => [
  31, year % 4 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
]

export default {
  name: 'AdditionalVisitTable',
  components: {
    BButton, BIconEmojiSmileFill, BIconEmojiFrownFill, BDropdown, BDropdownItem
  },
  props: [
    'date', 'groupName', 'visit'
  ],
  data() {
    return {
      visible: false,
      countVisits: {},
      visitStatus: [
        { value: 'О', text: `${emojiStatusForAdditional('О')} Не был` },
        { value: 'Был', text: `${emojiStatusForAdditional('Был')} Был` },
      ],
    }
  },
  computed: {
    no_of_days() {
      return monthDays((new Date(`${this.date}T00:00:00`)).getFullYear())[(new Date(`${this.date}T00:00:00`)).getMonth()]
    },
  },
  watch: {
    visit() {
      this.countVisits = {}
    }
  },
  methods: {
    checkVisit(day, child) {
      const visitStatus = this._visitStatus(day, child)
      return (visitStatus ? emojiStatusForAdditional(visitStatus.status) : '')
    },
    // eslint-disable-next-line no-underscore-dangle
    _visitStatus(day, child) {
      const visit = child.dates.find(d => moment(d.date).isSame(this.date, 'day') || null)
      return visit
    },
    getVisitId(day, child) {
      const { date } = this.date
      date.set('date', day)
      const visit = child.dates.find(d => moment(d.date)
          .isSame(date, 'day')) || null
      return (visit) ? visit.id : visit
    },
    isWeekend(day) {
      const date = new Date(`${this.date}T00:00:00`)
      date.setDate(day)
      return date.getDay() === 6 || date.getDay() === 0
    },
    sumVisits(group, child) {
      return this.$store.state.info.countVisits[group][child.id]
    },
    handleEditVisit(data) {
      if (data.id) {
          this.$store.dispatch('info/listVisitAdditionalScheduleUpdate', {
            id: data.id,
            visit_date: `${this.no_of_days.format('YYYY-MM')}-${data.day}`,
            visit_status: data.status,
            section_id: data.section_id
        }).then(() => {
          this.$store.dispatch('info/getVisitAdditional', { date: this.no_of_days.format('YYYY-MM') })
          this.$toast.success('Визит изменен', { timeout: 2000 })
          this.isAddListVisitSidebarActive = false
        }).catch(err => {
          this.$toast.error('Не удалось сохранить визит', { timeout: 2000 })
        })
      } else {
        this.$store.dispatch('info/listVisitAdditionalScheduleCreate', {
          children: data.children,
          worker: JSON.parse(localStorage.user).id,
          visit_date: `${this.no_of_days.format('YYYY-MM')}-${data.day}`,
          visit_status: data.status,
          section_id: data.section_id
        }).then(() => {
          this.$store.dispatch('info/getVisitAdditional')
          this.isAddListVisitAdditionalSidebarActive = false
          this.$toast.success('Визит сохранён', { timeout: 2000 })
        }).catch(err => {
          this.$toast.error('Не удалось сохранить визит', { timeout: 2000 })
        })
      }
    }
  }
}
</script>

<style scoped>
</style>

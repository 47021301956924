<template>
  <b-row>
      <b-col cols="4" class="mb-1">
        <b-form-group
            label="Фильтр"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                @input="filterData"
                placeholder="Поиск"
            />
            <b-input-group-append>
              <b-button
                  :disabled="!filter"
                  @click="filter = ''; filterData()"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    <b-col cols="12">
      <b-table
          :selected-variant="isDark ? 'custom-active' : 'active'"
          :fields="fields"
          :items="items"
          responsive
          show-empty
          empty-text="Нет детей"
          :select-mode="'multi'"
          selectable
          @row-selected="onRowSelected"
      >
      </b-table>
    </b-col>
    <b-col cols="12">
      <b-pagination
          v-model="currentPage"
          class="mt-1"
          align="right"
          :total-rows="totalCount"
          :per-page="limit"
          @change="pageChanged"
      />
    </b-col>
<!--    <b-col cols="12">-->
<!--      <b-pagination-->
<!--          v-model="currentPage"-->
<!--          class="mt-1"-->
<!--          align="right"-->
<!--          :total-rows="totalCount"-->
<!--          :per-page="limit"-->
<!--          @change="pageChanged"-->
<!--      />-->
<!--    </b-col>-->
  </b-row>
</template>

<script>
import {
  BButton, BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink, BPagination, BRow,
  BTable,
} from "bootstrap-vue"
import store from "@/store";

export default {
  name: "ChildrenTable",
  components: {
    BButton, BLink,
    BTable, BFormGroup, BInputGroup, BFormInput,
    BInputGroupAppend, BPagination, BRow, BCol
  },
  props: {
    onlyAvailableChildren: Boolean,
  },
  data() {
    return {
      fields: [
        {
          key: 'group_name',
          label: 'Группа'
        },
        {
          key: 'full_name',
          label: 'Имя'
        },
        // {
        //   key: 'total_price',
        //   label: 'Цена к оплате'
        // },
      ],
      filter: '',
      selected: [],
      currentPage: 1,
      limit: 10,
    }
  },
  computed: {
    totalCount() {
      return this.$store.state.info.childrenCount
    },
    items() {
      return this.$store.state.info.childrenList
    },
    // totalCount() {
    //   return this.$store.state.info.servicesCount
    // },
    isDark() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    },
  },
  created() {
    this.fetchData(1)
  },
  methods: {
    fetchData(page = 1) {
      const params = {
        limit: this.limit,
        offset: page === 1 ? 0 : (page * this.limit) - this.limit,
      }
      // if (this.filter !== '') {
      //   params.search = this.filter
      // }

      // params
      if (this.filter != '') {
        params.search = this.filter
      } else {
        delete params.search
      }
console.log({ onlyAvailableChildren: this.onlyAvailableChildren })
      if (this.onlyAvailableChildren){
        params.group_year_gte = +new Date().getFullYear() + 1
        this.$store.dispatch('info/getChildren', params)
        return
      }
      this.$store.dispatch('info/getChildren', params)
    },
    filterData() {
      if (this.filter != '') {
        this.fetchData(1)
      } else {
        this.fetchData(1)
      }
    },
    pageChanged(page) {
      this.$emit('new-page')
      this.fetchData(page)
    },
    onRowSelected(items) {
      this.$emit('items-selected', items)
    }
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/base/components/variables-dark";
.table-custom-active {
  background-color: $theme-dark-body-bg;
}
</style>

<template>
  <list-visit-additional :kind="'common'" />
</template>
<script>
import ListVisitAdditional from './ListVisitAdditional.vue'

export default {
    name: 'ListCommonSections',
    components: {
        ListVisitAdditional,
    },
}
</script>

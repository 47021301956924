export default function emojiStatus(status) {
  const statuses = {
    Был: '🟢',
    О: '🟡', // Отсутствовал без предупреждения
    Ж: '⚪', // Отсутствовал по желанию родителей
  }

  return statuses[status]
}

export function emojiStatusForAdditional(status) {
  const statuses = {
    Был: '🟢',
    О: '⚪',
  }

  return statuses[status]
}
export function emojiStatusForWorkers(status) {
  const statuses = {
    От: '🟣', // Отпуск
    ДО: '🟠', // административный
    ОЖ: '🔵', // Отпуск по уходу за ребёнком
    Желый: '🟡',
    Б: '⚫', // больничный
  }

  return statuses[status]
}
